/* ****************************************************
      
    This file is responsible for creating the Not Found page in case anyone enters an incorrect URL

*****************************************************/

import React from 'react';

const NotFound = () => <h2 style={{ fontFamily: "Comfortaa, sans-serif" }} >Not found</h2>

export default NotFound;
